<template>
  <div class="dashboard">
    <div class="row">
      <div class="col-12">
        <ibox :title="'Produkty'">
          <template slot="content" slot-scope="{ setLoading }">
            <DataTable
              :excel="true"
              url="neonail/pl/products"
              @loading="setLoading"
            />
          </template>
        </ibox>
      </div>
    </div>
  </div>
</template>
<script>
import Ibox from "@/components/base/Ibox.vue";
import DataTable from "@/components/DataTable/DataTable.vue";
export default {
  components: {
    Ibox,
    DataTable
  }
};
</script>
